import React from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Css File
import "./assets/sass/main.scss";

import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
// Routes File
import AllRoutes from "./Routes";
import { ToastContainer } from "react-toastify";
import Layout from "./Pages/MainLayout";
import HowItWorks from "./Pages/HowItWorks/HowItWorks";
import Register from "./Pages/Register/Register";
import SignUpbusiness from "./Pages/Register/SignUpbusiness";
import Address from "./Pages/Register/Address";
import BankDetails from "./Pages/Register/BankDetails";
import Login from "./Pages/Register/Login";
import Home from "./Pages/Home/Home";
import OurSolution from "./Pages/OurSolution/OurSolution";
import DukanToBrand from "./Pages/DukanToBrand/DukanToBrand";
import UploadJwellery from "./Pages/Dashboard/UploadJwellery";
import ProductList from "./Pages/Dashboard/ProductList";
import InfoProduct from "./Pages/Dashboard/InfoProduct";
import LoginPhone from "./Pages/Register/LoginPhone";
import Dashboard from "./Pages/Dashboard/Dashboard";
import AddCatalogue from "./Pages/Dashboard/Catalogue/AddCatalogue";
import CatalogueHome from "./Pages/Dashboard/Catalogue";
import CatalogueDetail from "./Pages/Dashboard/Catalogue/CatalogueDetails";
import Main from "./Pages/Dashboard/Main";
import Enquiry from "./Pages/Dashboard/Enquiry/enquiryTabs";
import Order from "./Pages/Dashboard/orders/orderTabs";
import { SocketProvider } from "./context/socket";
import ProtectedRoute from "./Routes/protectedRoutes";
import TokenProvider from "./tokenProvider";
import { AppProvider } from "./appContext";
import Preference from "./Pages/Register/Preference";
import Profile from "./Pages/Dashboard/profile";
import Performance from "./Pages/Dashboard/performance/index";
import ChangePassword from "./Pages/ChangePassword";
import ForgotPassword from "./Pages/Register/ForgotPassword";
import Pricing from "./Pages/Pricing";
function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <AppProvider>
        <TokenProvider>
          <SocketProvider>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <Layout>
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                      exact
                      path="/admin/supplier/register"
                      element={<Register />}
                    />
                    <Route
                      exact
                      path="/admin/supplier/sign-up-business"
                      element={<SignUpbusiness />}
                    />
                    <Route
                      exact
                      path="/admin/supplier/address"
                      element={<Address />}
                    />
                    <Route
                      exact
                      path="/admin/supplier/bankdetails"
                      element={<BankDetails />}
                    />
                    <Route
                      path="/change-password/:token"
                      element={<ChangePassword />}
                    />

                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route
                      exact
                      path="/how-it-works"
                      element={<HowItWorks />}
                    />
                    <Route
                      exact
                      path="/our-solution"
                      element={<OurSolution />}
                    />
                    <Route
                      exact
                      path="/dukan-to-brand"
                      element={<DukanToBrand />}
                    />
                      <Route
                      exact
                      path="/pricing"
                      element={<Pricing />}
                    />
                    <Route exact path="/dashboard" element={<Dashboard />} />
                    <Route
                      exact
                      path="/login-with-phone"
                      element={<LoginPhone />}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route exact path="/preference" element={<Preference />} />
                    <Route path="dashboard" element={<ProtectedRoute />}>
                      <Route index element={<Main />} />
                      <Route path="jewellery-list" element={<ProductList />} />
                      <Route path="jewellery-info" element={<InfoProduct />} />
                      <Route
                        path="upload-jewellery"
                        element={<UploadJwellery />}
                      />
                      <Route path="catalogue" element={<CatalogueHome />} />
                      <Route path="add-catalogue" element={<AddCatalogue />} />
                      <Route
                        path="catalogue-detail"
                        element={<CatalogueDetail />}
                      />
                      <Route path="enquiry" element={<Enquiry />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="order" element={<Order />} />
                      <Route path="performance" element={<Performance />} />
                    </Route>
                    <Route exact path="/dashboard" element={<Dashboard />} />
                  </Routes>
                </Layout>
              </BrowserRouter>
            </QueryClientProvider>
          </SocketProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </TokenProvider>
      </AppProvider>
    </>
  );
}

export default App;
